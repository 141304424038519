import { Injectable } from '@angular/core';
import { BehaviorSubject, from, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import * as locator from '@arcgis/core/rest/locator';
import AddressCandidate from '@arcgis/core/rest/support/AddressCandidate';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from 'src/app/config/app.config';
import { SnackbarService } from '../snackbar/snackbar.service';
import Point from '@arcgis/core/geometry/Point';
import { metersToMiles } from '../utility';
import { DynatraceService } from '../dynatrace/dynatrace.service';
import { NearestMedicalFacility, SuggestionResult } from './Location';

export interface AddressObject {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
}

export const google_urlFromAddressObj = (addrObj: AddressObject) =>
  addrObj?.address_line_1 && addrObj.city && addrObj.state && addrObj.zip_code
    ? `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        `${addrObj.address_line_1 ?? ''} ${addrObj.address_line_2 ?? ''}, ${
          addrObj.city ?? ''
        }, ${addrObj.state ?? ''} ${addrObj.zip_code ?? ''}`
      )}`.slice(0, 256)
    : undefined;

export const ESRI_AUTH_SESSION_KEY = 'esri_auth';
export const ESRI_AUTH_EXPIRATION = 60;

export interface EsriToken {
  token: string;
  expires: number;
  ssl: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private _currentLocation$ = new BehaviorSubject<GeolocationPosition>(null);

  /**
   * Fetch observable for an ESRI authentication token
   */
  esriToken$ = new Subject<EsriToken>();
  /**
   * Indicates that an ESRI token is being fetched
   */
  fetchingESRIToken = false;

  private _apiEndpoint = this.config.getConfig('apiBaseUrl');

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private snackbar: SnackbarService,
    private dynatrace: DynatraceService
  ) {}

  get location() {
    return this._currentLocation$.value;
  }

  get locationLatLongString() {
    return this.location?.coords?.latitude && this.location?.coords?.longitude
      ? `${this.location.coords.latitude},${this.location.coords.longitude}`
      : undefined;
  }

  currentLocation$(unsubscribe$: Subject<void>) {
    return this._currentLocation$.pipe(takeUntil(unsubscribe$));
  }

  askForLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => this._currentLocation$.next(position),
      (error) => console.warn(error),
      {
        enableHighAccuracy: true,
        timeout: 30000,
        maximumAge: 0,
      }
    );
  }

  /**
   * Indicates whether an ESRI token is currently valid
   *
   * @param token ESRI token
   */
  esriTokenIsValid(token: EsriToken) {
    return token?.token?.length > 0 && Date.now() < token.expires;
  }

  /**
   * Gets an ESRI auth token using a username/password,
   * storing the token in session storage
   */
  getESRIToken(): Promise<EsriToken> {
    let token: EsriToken;
    try {
      token = JSON.parse(sessionStorage.getItem(ESRI_AUTH_SESSION_KEY) ?? '{}');
    } catch {
      token = null;
    }

    // Current token is valid
    if (this.esriTokenIsValid(token)) {
      return Promise.resolve(token);
    }

    // Already fetching a new token
    if (this.fetchingESRIToken) {
      return this.esriToken$.toPromise();
    }

    // Request a new token (see https://developers.arcgis.com/rest/users-groups-and-items/generate-token)
    this.fetchingESRIToken = true;

    const formData = new FormData();
    formData.set('username', this.config.getConfig('arcGISUsername'));
    formData.set('password', this.config.getConfig('arcGISPassword'));
    formData.set('client', 'referer');
    formData.set('referer', this.config.getConfig('app_url'));
    formData.set('expiration', `${ESRI_AUTH_EXPIRATION}`);
    formData.set('f', 'pjson');

    return this.http
      .post<EsriToken>(this.config.getConfig('arcGISTokenEndpoint'), formData)
      .pipe(
        tap((token) => {
          sessionStorage.setItem(ESRI_AUTH_SESSION_KEY, JSON.stringify(token));
          this.esriToken$.next(token);
          this.fetchingESRIToken = false;
        }),
        catchError((_) => {
          this.esriToken$.next(null);
          this.fetchingESRIToken = false;
          return of(null);
        })
      )
      .toPromise();
  }

  async geoCodeAddress(address: string): Promise<AddressCandidate[]> {
    return locator.addressToLocations(
      'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer',
      { address: { address } }
    );
  }

  // reverseGeoCodeAddress() {
  //   locator.locationToAddress('http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?Location=', {
  //     location: new Point({
  //       latitude: this.location?.coords?.latitude,
  //       longitude: this.location?.coords?.longitude
  //     })
  //   })
  // }

  /**
   * Searches for a full address object using the result of an autosuggestion
   *
   * @param address Address text
   * @param magicKey Magic key (from autosuggestion)
   */
  async searchWithMagicKey(
    address: string,
    magicKey: string
  ): Promise<AddressCandidate[]> {
    return locator.addressToLocations(
      'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer',
      {
        magicKey,
        address: { address },
        // https://developers.arcgis.com/rest/geocode/api-reference/geocoding-service-output.htm
        outFields: [
          'Place_addr',
          'PlaceName',
          'StAddr',
          'Postal',
          'Region',
          'City',
          'Phone',
          'URL',
          'Distance',
          'Subregion',
        ],
      }
    );
  }

  /**
   * Find a list of text suggestions given the current location and a partial address
   *
   * See https://developers.arcgis.com/documentation/mapping-apis-and-services/search/autosuggest/#apis and
   * https://developers.arcgis.com/rest/geocode/api-reference/geocoding-find-address-candidates.htm
   *
   * @param address Address string
   */
  async autoSuggest(text: string): Promise<SuggestionResult[]> {
    if (text?.length > 0) {
      const location =
        !isNaN(this._currentLocation$.value?.coords?.longitude) &&
        !isNaN(this._currentLocation$.value?.coords?.latitude)
          ? `${this._currentLocation$.value?.coords?.longitude},${this._currentLocation$.value?.coords?.latitude}`
          : null;
      return this.http
        .get<{ suggestions: SuggestionResult[] }>(
          'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest',
          {
            params: {
              f: 'json',
              text,
              location,
              token: this.config.getConfig('arcGISApiKey'),
              maxCandidates: 6,
              sourceCountry: 'USA',
            },
          }
        )
        .pipe(map((response) => response.suggestions))
        .toPromise();
    } else {
      return of([] as SuggestionResult[]).toPromise();
    }
  }

  async getNearestMedicalFacilities(
    lat: string,
    long: string
  ): Promise<NearestMedicalFacility[]> {
    if (lat?.length > 0 && long?.length > 0) {
      // Get ESRI token
      const token = await this.getESRIToken();

      return (
        this.http
          .get<{ candidates: AddressCandidate[] }>(
            'https://geocodeqa.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates',
            {
              params: {
                address: 'Emergency Room',
                category: 'Hospital',
                outFields:
                  'Place_addr,PlaceName,StAddr,Postal,Region,City,Phone,URL,Distance',
                location: `${long},${lat}`,
                f: 'json',
                token: token?.token,
              },
            }
          )
          // return from(
          //   locator.addressToLocations(
          //     'http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer',
          //     {
          //       address: {
          //         address: 'Emergency Room',
          //       },
          //       categories: ['Hospital'],
          //       location: new Point({
          //         latitude: parseFloat(lat),
          //         longitude: parseFloat(long),
          //       }),
          //       // https://developers.arcgis.com/rest/geocode/api-reference/geocoding-service-output.htm
          //       outFields: [
          //         'Place_addr',
          //         'PlaceName',
          //         'StAddr',
          //         'Postal',
          //         'Region',
          //         'City',
          //         'Phone',
          //         'URL',
          //         'Distance',
          //       ],
          //     },
          //     {
          //       token: token?.token,
          //     }
          //   )
          // )
          .pipe(
            map((res) =>
              res?.candidates
                ?.filter(
                  (r) =>
                    // Emergency room facilities have a '-ER' suffix
                    /-ER|Emergency Room/i.test(r.attributes?.PlaceName) &&
                    // Exclude children and animal/veterinary hospitals
                    !/children|animal|veterinary/i.test(r.attributes?.PlaceName)
                )
                // Eliminate duplicates
                ?.reduce(
                  (set, result) =>
                    set.some(
                      (r) =>
                        r.attributes?.PlaceName === result.attributes?.PlaceName
                    )
                      ? set
                      : [...set, result],
                  []
                )
                ?.map((r) => {
                  const miles = metersToMiles(r.attributes?.Distance);
                  const distance_in_miles =
                    Math.round((miles + Number.EPSILON) * 100) / 100;
                  return {
                    zip: r.attributes?.Postal,
                    state: r.attributes?.Region,
                    city: r.attributes?.City,
                    street_address: r.attributes?.StAddr,
                    facility_name: `${r.attributes?.PlaceName?.replace(
                      /-ER|Emergency Room/i,
                      ' ER'
                    )} (${distance_in_miles} mi)`,
                    distance_in_miles,
                  } as NearestMedicalFacility;
                })
                // Include first 5 results only
                ?.slice(0, 5)
            ),
            catchError((e) => {
              const errorCode =
                e instanceof HttpErrorResponse
                  ? ` (${e.status}: ${e.message})`
                  : '';
              this.dynatrace.reportError(
                `Error getting nearest medical facilities for lat: ${lat}, long: ${long}${errorCode}.`
              );
              return of(undefined);
            })
          )
          .toPromise()
      );
    }
    return undefined;
  }
}
