type PJBLowerEnv = 'sbx' | 'dev' | 'qa';

/**
 * Given a lower env name, returns the config for that env from `local.config.json`
 *
 * Please create a `local.config.json` file in the /src/config folder, pasting the
 * contents of the "web-local-config" secret in Vault.
 *
 * @param env Lower env name
 */
const localConfig = (env: PJBLowerEnv) => {
  try {
    const LOCAL_CONFIG = require('./local.config.json');
    return {
      production: false,
      envName: 'local',
      azure_client_id: LOCAL_CONFIG.azureClientID,
      graphUrl: 'https://graph.microsoft.com/v1.0',
      app_url: LOCAL_CONFIG.appURL,
      azure_tenant: LOCAL_CONFIG.azureTenantID,
      apiBaseUrl: LOCAL_CONFIG.apiBaseUrl[env],
      dynatraceScript: LOCAL_CONFIG.dynatraceScript,
      weatherApiUrl: LOCAL_CONFIG.weatherApiUrl,
      weatherApiKey: LOCAL_CONFIG.weatherApiKey,
      arcGISApiKey: LOCAL_CONFIG.arcGISApiKey,
      arcGISUsername: LOCAL_CONFIG.arcGISUsername,
      arcGISPassword: LOCAL_CONFIG.arcGISPassword,
      arcGISTokenEndpoint: LOCAL_CONFIG.arcGISTokenEndpoint,
    };
  } catch {
    return null;
  }
};

export const environments = {
  'local-sbx': localConfig('sbx'),
  'local-dev': localConfig('dev'),
  'local-qa': localConfig('qa'),
  sbx: {
    production: false,
    envName: 'sbx',
    azure_client_id: '@AZURE-CLIENT-ID@',
    graphUrl: 'https://graph.microsoft.com/v1.0',
    app_url: '@APP-URL@',
    azure_tenant: '@AZURE-TENANT-ID@',
    apiBaseUrl: '@API-BASE-URL@',
    dynatraceScript: '@DYNATRACE-SCRIPT@',
    weatherApiUrl: '@WEATHER-API-URL@',
    weatherApiKey: '@WEATHER-API-KEY@',
    arcGISApiKey: '@ARC-GIS-API-KEY@',
    arcGISUsername: '@ARC-GIS-API-USER@',
    arcGISPassword: '@ARC-GIS-API-PASS@',
    arcGISTokenEndpoint: '@ARC-GIS-API-TOKEN-URL@'
  },
  dev: {
    production: false,
    envName: 'dev',
    azure_client_id: '@AZURE-CLIENT-ID@',
    graphUrl: 'https://graph.microsoft.com/v1.0',
    app_url: '@APP-URL@',
    azure_tenant: '@AZURE-TENANT-ID@',
    apiBaseUrl: '@API-BASE-URL@',
    dynatraceScript: '@DYNATRACE-SCRIPT@',
    weatherApiUrl: '@WEATHER-API-URL@',
    weatherApiKey: '@WEATHER-API-KEY@',
    arcGISApiKey: '@ARC-GIS-API-KEY@',
    arcGISUsername: '@ARC-GIS-API-USER@',
    arcGISPassword: '@ARC-GIS-API-PASS@',
    arcGISTokenEndpoint: '@ARC-GIS-API-TOKEN-URL@'
  },
  qa: {
    production: false,
    envName: 'qa',
    azure_client_id: '@AZURE-CLIENT-ID@',
    graphUrl: 'https://graph.microsoft.com/v1.0',
    app_url: '@APP-URL@',
    azure_tenant: '@AZURE-TENANT-ID@',
    apiBaseUrl: '@API-BASE-URL@',
    dynatraceScript: '@DYNATRACE-SCRIPT@',
    weatherApiUrl: '@WEATHER-API-URL@',
    weatherApiKey: '@WEATHER-API-KEY@',
    arcGISApiKey: '@ARC-GIS-API-KEY@',
    arcGISUsername: '@ARC-GIS-API-USER@',
    arcGISPassword: '@ARC-GIS-API-PASS@',
    arcGISTokenEndpoint: '@ARC-GIS-API-TOKEN-URL@'
  },
  prod: {
    production: true,
    envName: 'prod',
    azure_client_id: '@AZURE-CLIENT-ID@',
    graphUrl: 'https://graph.microsoft.com/v1.0',
    app_url: '@APP-URL@',
    azure_tenant: '@AZURE-TENANT-ID@',
    apiBaseUrl: '@API-BASE-URL@',
    dynatraceScript: '@DYNATRACE-SCRIPT@',
    weatherApiUrl: '@WEATHER-API-URL@',
    weatherApiKey: '@WEATHER-API-KEY@',
    arcGISApiKey: '@ARC-GIS-API-KEY@',
    arcGISUsername: '@ARC-GIS-API-USER@',
    arcGISPassword: '@ARC-GIS-API-PASS@',
    arcGISTokenEndpoint: '@ARC-GIS-API-TOKEN-URL@'
  },
};
